/*csslint important:false*/

/* ==========================================================================
   Pure Base Extras
   ========================================================================== */

/**
 * Extra rules that Pure adds on top of Normalize.css
 */

/**
 * Always hide an element when it has the `hidden` HTML attribute.
 */

.pure .hidden,
.pure [hidden] {
    display: none !important;
}

/**
 * Add this class to an image to make it fit within it's fluid parent wrapper while maintaining
 * aspect ratio.
 */

.pure .pure-img {
    max-width: 100%;
    height: auto;
    display: block;
}