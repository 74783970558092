/*csslint regex-selectors:false, known-properties:false, duplicate-properties:false*/

.pure-g {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

    /*
    Sets the font stack to fonts known to work properly with the above letter
    and word spacings. See: https://github.com/yahoo/pure/issues/41/

    The following font stack makes Pure Grids work on all known environments.

    * FreeSans: Ships with many Linux distros, including Ubuntu

    * Arimo: Ships with Chrome OS. Arimo has to be defined before Helvetica and
      Arial to get picked up by the browser, even though neither is available
      in Chrome OS.

    * Droid Sans: Ships with all versions of Android.

    * Helvetica, Arial, sans-serif: Common font stack on OS X and Windows.
    */
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

    /* Use flexbox when possible to avoid `letter-spacing` side-effects. */
    display: flex;
    flex-flow: row wrap;

    /* Prevents distributing space between rows */
    align-content: flex-start;
}

/* Opera as of 12 on Windows needs word-spacing.
   The ".opera-only" selector is used to prevent actual prefocus styling
   and is not required in markup.
*/
.opera-only :-o-prefocus,
.pure-g {
    word-spacing: -0.43em;
}

.pure-u {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*
Resets the font family back to the OS/browser's default sans-serif font,
this the same font stack that Normalize.css sets for the `body`.
*/
.pure-g [class *= "pure-u"] {
    font-family: sans-serif;
}
