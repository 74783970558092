.pure-u-0,
.pure-u-1,
.pure-u-0-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-0-5,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-0-7,
.pure-u-1-7,
.pure-u-2-7,
.pure-u-3-7,
.pure-u-4-7,
.pure-u-5-7,
.pure-u-6-7,
.pure-u-7-7,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-0-24,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

.pure-u-0,
.pure-u-0-1,
.pure-u-0-5,
.pure-u-0-7,
.pure-u-0-24 {
    width: 0%;
    display: none;
}

.pure-u-1-24 {
    width: 4.1667%;
}

.pure-u-1-12,
.pure-u-2-24 {
    width: 8.3333%;
}

.pure-u-1-8,
.pure-u-3-24 {
    width: 12.5000%;
}

.pure-u-1-7 {
    width: 14.2857%;
}

.pure-u-1-6,
.pure-u-4-24 {
    width: 16.6667%;
}

.pure-u-1-5 {
    width: 20%;
}

.pure-u-5-24 {
    width: 20.8333%;
}

.pure-u-1-4,
.pure-u-6-24 {
    width: 25%;
}

.pure-u-2-7 {
    width: 28.5714%;
}

.pure-u-7-24 {
    width: 29.1667%;
}

.pure-u-1-3,
.pure-u-8-24 {
    width: 33.3333%;
}

.pure-u-3-8,
.pure-u-9-24 {
    width: 37.5000%;
}

.pure-u-2-5 {
    width: 40%;
}

.pure-u-5-12,
.pure-u-10-24 {
    width: 41.6667%;
}

.pure-u-3-7 {
    width: 42.8571%;
}

.pure-u-11-24 {
    width: 45.8333%;
}

.pure-u-1-2,
.pure-u-12-24 {
    width: 50%;
}

.pure-u-13-24 {
    width: 54.1667%;
}

.pure-u-4-7 {
    width: 57.1429%;
}

.pure-u-7-12,
.pure-u-14-24 {
    width: 58.3333%;
}

.pure-u-3-5 {
    width: 60%;
}

.pure-u-5-8,
.pure-u-15-24 {
    width: 62.5000%;
}

.pure-u-2-3,
.pure-u-16-24 {
    width: 66.6667%;
}

.pure-u-17-24 {
    width: 70.8333%;
}

.pure-u-5-7 {
    width: 71.4286%;
}

.pure-u-3-4,
.pure-u-18-24 {
    width: 75%;
}

.pure-u-19-24 {
    width: 79.1667%;
}

.pure-u-4-5 {
    width: 80%;
}

.pure-u-5-6,
.pure-u-20-24 {
    width: 83.3333%;
}

.pure-u-6-7 {
    width: 85.7143%;
}

.pure-u-7-8,
.pure-u-21-24 {
    width: 87.5000%;
}

.pure-u-11-12,
.pure-u-22-24 {
    width: 91.6667%;
}

.pure-u-23-24 {
    width: 95.8333%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-7-7,
.pure-u-24-24 {
    width: 100%;
}