/* scrollable menus */
.pure-menu-scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
}

.pure-menu-scrollable .pure-menu-list {
    display: block;
}

.pure-menu-horizontal.pure-menu-scrollable .pure-menu-list {
    display: inline-block;
}

.pure-menu-horizontal.pure-menu-scrollable {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    /* a little extra padding for this style to allow for scrollbars */
    padding: .5em 0;
}

.pure-menu-horizontal.pure-menu-scrollable::-webkit-scrollbar {
    display: none;
}
